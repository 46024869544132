// used on student landing page (schoolToStudents) and students page
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { StudentsService } from '../students/students.service';
import { StringUtil } from '../shared/string-util';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { UpdateStudentComponent } from '../update-student/update-student.component';
import { ModalController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { AgencyDetailsService } from '../agency-details/agency-details.service';
import { Components } from '@ionic/core';
import { SchoolToStudentsService } from '../school-to-students/schoolToStudents.service';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'assign-student-agency',
  templateUrl: './assign-student-agency.component.html',
  styleUrls: ['./assign-student-agency.component.scss'],
})

export class AssignStudentAgencyComponent implements OnInit {
  @Input() students;
  @Input() schoolIdContext;
  @Input() schoolNameContext;
  @Input() yearId;
  @Input() type;
  @Input() yearDescription;
  @Input() waitlist;
  @Input() agenccyIdContext;
  @Input() masterCalendarCode;
  @Input() modal: Components.IonModal;
  studentProgram: any = {};
  loading = false;
  queryText = '';
  rows: any = [];
  filteredRows: any = [];
  invalidIds: any = [];
  invalidIdText: any = '';
  invalidFormatIds: any = [];
  invalidFormatText: any = '';
  addedIds: any = [];
  addedIdText: any = '';
  testCb: 'test clipboard';
  hideCbText = true;
  //wl_filteredRows: any = [];
  columns = [
    { name: 'Name', prop: 'concatName' },
    { name: 'Student ID', prop: 'lausdId' },
    { name: 'School', prop: 'school.schoolName' },
    { name: 'DOB', prop: 'dobFormatted' },
    { name: 'Grade', prop: 'gradeConfig' },
    { name: 'Source', prop: 'source' },
  ];


  columnMode = ColumnMode.force;
  filterColumns = ['firstName', 'middleName', 'lastName', 'studentId'];
  defaultSort = [{ prop: 'lastName', dir: 'asc' }];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  innerWidthGreater: boolean;
  typeName: string;
  isAddEnabled: boolean = true;

  constructor(private studentsService: StudentsService,
    private agencyDetailsService: AgencyDetailsService,
    private schoolToStudentsService: SchoolToStudentsService,
    public modalController: ModalController,
    private clipboardService: ClipboardService) { }


  ngOnInit() {
    this.hideCbText = true;
    this.innerWidthGreater = window.innerWidth <= 728;
    console.log('here; ', this.type, this.yearId, this.waitlist)
    if (this.type === 1) {
      this.typeName = 'Core';
    } else {
      this.typeName = 'Supp';
    }
    if(this.waitlist){
      this.showResult()
    }
    else{
      const x = document.getElementById('myDIV');
      x.style.display = 'none';
    }

  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728;
  }
  copyToClipboard(){
    // this.clipboardService.copyFromContent(invalidIds.join(" ,"));
    this.clipboardService.copyFromContent('test clipboard');
  }
  // fetching students to enroll
  fetchData() {
    var colors = [
      '#68C182',
      '#FAD55C',
      //'#F7F36B', // yellow
      '#ED6647',
      '#A2BF39',
      '#FFB54D',
      '#E371B2',
      '#47BDEF',
      '#267DB3',
      '#A75DBA',
      '#8561C8',
    ];

    event.stopPropagation();
    console.log(this.queryText);
    // user must enter full 10 digit student ID to enable search function
    if (this.queryText.length >= 10) {
      this.loading = true;
      this.queryText = this.queryText.trim().toUpperCase();
      var districtIds = this.queryText.replace(/[^0-9a-z,]/gi, '').split(',');
      
      const studentsSearchRequest = {
        schoolId:  parseInt(this.schoolIdContext),
        agencyId: 0,
        masterCalendarCode: this.masterCalendarCode,
        districtIds: districtIds
      };
      // this.schoolToStudentsService.getStudentsByAgencyByMasterCalendar(this.schoolIdContext, this.queryText, this.masterCalendarCode).subscribe((data: any) => {
      this.schoolToStudentsService.getStudentsBySearchCriteria(studentsSearchRequest).subscribe((data: any) => {
        this.students = data.students;
        data.students.forEach(element => {
          element.concatName = element.lastName + ', ' + element.firstName;
          element.dobFormatted = moment(element.dob).format('MM/DD/YYYY');
          if(element.backgroundcolor == undefined){
            const randomIndex = Math.floor(Math.random() * Math.floor(colors.length));
            element.backgroundcolor = colors[randomIndex];
            }
        });
        this.rows = data.students;
        this.filteredRows = this.rows;
        this.filteredRows.map(fr => fr.isAlreadyAdded = false);
        this.invalidIds = data.invalidDistrictIds;
        this.addedIds = data.addedDistrictIds;

        this.invalidIdText = this.invalidIds.join(", ");
        this.addedIdText = this.addedIds.join(", ");

        console.log('fetchData(): done');
        // test out the loading bar (todo: remove later)
        const that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    } else {
      this.loading = false;
      this.filteredRows = [];
    }
  }
  SearchOnEnter(event){
    if(event.keyCode === 13){
        this.showResult();
    }
  }
  validateDistrictIds(){
    if (this.queryText.length >= 10) {
      var districtIds = this.queryText.replace(/[^0-9a-z,]/gi, '').split(',');
      var regex = new RegExp('[0-9]{6}[A-Z]{1}[0-9]{3}$');
      this.invalidFormatIds = [];
      this.invalidFormatText = '';

      districtIds.forEach(d => {
        if(!regex.test(d.toUpperCase())){
          this.invalidFormatIds.push(d);
        }
      })
      if(this.invalidFormatIds.length > 0)
      {
        this.invalidFormatText = this.invalidFormatIds.join(", ");
        return false;
      }
      else
      {
        return true;
      }
    }
    else{
      return false;
    }


  }
  // hides 'add new student' button until user has searched a student's ID to prevent user from adding duplicates
  showResult() {
    this.addedIds = "";
    this.invalidIds = "";
    const x = document.getElementById('myDIV');
    if(this.waitlist){
      x.style.display = 'block';
      this.fetchWaitListData();
    }
    else{
      if(this.validateDistrictIds()){
        x.style.display = 'block';
        this.fetchData();
      }
      // if (x.style.display === 'none') {
      //   x.style.display = 'block';
      // } else {
      //   x.style.display = 'none';
      // }

    }
  }


  addAllStudents(){
    let studentPrograms: any = [];
    this.loading = true;
    this.filteredRows.filter(fr => fr.isAlreadyAdded == false).forEach(s => {
      var program : any = {};
      program.studentId = s.id;
      program.agencyId = this.profile.agency;
      program.isActive = true;
      program.student = s;
      program.lausdId = s.lausdId;
      program.calendartype = this.type.toString();
      program.calendarid = this.yearId;
      program.schoolid = Number(this.schoolIdContext);
      program.masterCalendarCode = this.masterCalendarCode;
      program.gradeConfig = s.gradeConfig;
      studentPrograms.push(program);
    });
    this.studentsService.assignAllStudentsToAgency(studentPrograms).subscribe((result) => {
      this.filteredRows.forEach(row => {
        row.isAlreadyAdded = true;
      });

      this.loading = false;
    },
    (error)=>{
      this.loading = false;
    });


  }

  // assigns a student to a school
  assignStudent(row, event) {
    event.stopPropagation();
    console.log(row);
    event.target.disabled = true;
    this.studentProgram.studentId = row.id;
    this.studentProgram.agencyId = this.profile.agency;
    this.studentProgram.isActive = true;
    this.studentProgram.student = row;
    this.studentProgram.lausdId = row.lausdId;
    this.studentProgram.calendartype = this.type.toString();
    this.studentProgram.calendarid = this.yearId;
    this.studentProgram.schoolid = Number(this.schoolIdContext);
    this.studentProgram.masterCalendarCode = this.masterCalendarCode;
    this.studentProgram.gradeConfig = row.gradeConfig;
    this.studentsService.assignStudentAgency(this.studentProgram, this.modal).subscribe((result) => {
      // this.filteredRows = this.filteredRows.filter(fr => fr.id != row.id);
      this.filteredRows.map(fr => fr.lausdId == row.lausdId ? fr.isAlreadyAdded = true : fr.isAlreadyAdded = fr.isAlreadyAdded);
      row.isAlreadyAdded = true;
      event.target.disabled = true;
      // this.fetchData();
    },
    (error)=>{

    });
  }

  wl_assignStudent(row, event) {
    console.log(row);
    event.target.disabled = true;
    this.studentProgram.studentId = row.id;
    this.studentProgram.agencyId = this.profile.agency;
    this.studentProgram.isActive = true;
    this.studentProgram.student = row;
    this.studentProgram.lausdId = row.lausdId;
    this.studentProgram.calendartype = this.type.toString();
    this.studentProgram.calendarid = this.yearId;
    this.studentProgram.schoolid = Number(this.schoolIdContext);
    this.studentsService.assignStudentAgency(this.studentProgram, this.modal).subscribe(() => {
      this.fetchData();
    });
  }

  // create a new student
  async addStudent() {
    const newStudentProgram = { schoolid: Number(this.schoolIdContext) };
    const modal = await this.modalController.create({
      component: UpdateStudentComponent,
      componentProps: {
        student: newStudentProgram,
        update: false,
        schoolIdContext: this.schoolIdContext,
        schoolNameContext: this.schoolNameContext,
        yearId: this.yearId,
        type: this.type.toString(),
        typeName: this.typeName,
        yearDescription: this.yearDescription,
        masterCalendarCode: this.masterCalendarCode
      }
    });
    this.modal.dismiss();
    return await modal.present();
  }

  filterRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );
    if (safeQueryText.length === 10) {
      console.log('filterRows(): safeQueryText: ', safeQueryText);
      this.filteredRows = this.rows.filter((row: any) => {
        if (!safeQueryText) {
          return false;
        }
        const filterRegExp = new RegExp(safeQueryText, 'i');

        for (const filterColumn of this.filterColumns) {
          const colValue = row[filterColumn];
          // toString() is needed if we want to search on numbers
          if (
            colValue &&
            colValue
              .toString()
              .toLowerCase()
              .match(filterRegExp)
          ) {
            return true;
          }
        }
        return false;
      });
      // Whenever the filter changes, always go back to the first page
      if (this.table != undefined) {
        this.table.offset = 0;
      }
    } else {
      return false;
    }
  }

  ngAfterViewChecked() {
    // When we resize the browser, or change the input rows for the datatable, the column widths
    // are re-calculated automatically. But, for some reason, when we refresh the page, the column
    // width recalculation fails causing some columns to not be displayed.
    // Workaround: Force the table to always recalculate after the view is checked.
    // See: https://github.com/swimlane/ngx-datatable/issues/1733
    this.recalculateData();
  }

  recalculateData() {
    // See: https://swimlane.gitbook.io/ngx-datatable/cd
    this.filteredRows = [...this.filteredRows];
  }

  // get user's profile details
  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

    // fetching wait list students to enroll
    fetchWaitListData() {
      console.log('fetchWaitListData(): start');
      //console.log(this.queryText);
      // user must enter full 10 digit student ID to enable search function
      //if (this.queryText.length === 10) {
        this.loading = true;
        this.schoolToStudentsService.getWaitListStudentsByAgencyBySchool(this.schoolIdContext, 210).subscribe((data: any) => {
          console.log('fetchWaitListData(): response', data);
          this.students = data;
          data.forEach(element => {
            element.concatName = element.lastName + ', ' + element.firstName;
            element.dobFormatted = moment(element.dateApplied).format('MM/DD/YYYY');
          });
          this.rows = data;
          this.filteredRows = this.rows;
          console.log('fetchWaitListData(): done', data);
          if(data.length === 0){
            console.log('No pending applications available at this time');
            const x = document.getElementById('myDIV');
            if (x.style.display === 'none') {
              x.style.display = 'block';
            } else {
              x.style.display = 'none';
            }
          }
          // test out the loading bar (todo: remove later)
          const that = this;
          setTimeout(function () {
            that.loading = false;
          }, 1000);
        });
      //} else {
      //  this.loading = false;
      //  this.filteredRows = [];
      //}
    }
}

// used on agencies page and agency details
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { AgenciesService } from '../agencies/agencies.service';
import { PopoverController } from '@ionic/angular';
import { DeactivateAgencyComponent } from '../deactivate-agency/deactivate-agency.component';

@Component({
  selector: 'update-agency',
  templateUrl: './update-agency.component.html',
  styleUrls: ['./update-agency.component.scss'],
})
export class UpdateAgencyComponent implements OnInit {
  public agencyForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() agency;
  agencies: any;
  @Input() modal: Components.IonModal;

  showAgencyRate = false;
  agRate: any;

  constructor(public router: Router, public formBuilder: FormBuilder,
    private agencyService: AgenciesService, public popoverController: PopoverController) {
    this.agencyForm = formBuilder.group({
      name: ['', Validators.required],
      shortCode: ['', Validators.required],
     // agencyId: [''],
      contactName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      contactPhone: ['', [Validators.required, Validators.minLength(14)]],
      contactEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      type: ['', Validators.required]
    });
  }
  // create new agency
  createAgency() {
    console.log(this.agencyForm);
    const agency = this.agencyForm.value;
    //agency.agencyId = Number(agency.agencyId);
    agency.school = [];
    agency.type = Number(agency.type);
    agency.status = 'Active';
    agency.isActive = true;
    agency.createDate = new Date();
    agency.updateDate = new Date();
    agency.enrolledSchoolCount = 1;
    console.log(agency);
    this.agencyService.createAgency(agency).add(() => {
      this.modal.dismiss();
    });
  }

  optionsFn(item) {
    console.log(item);
  }
  // update existing agency
  updateAgency() {
    console.log(this.agency);
    this.agency.name = this.agencyForm.value.name;
    this.agency.contactName = this.agencyForm.value.contactName;
    this.agency.agencyRate = this.agencyForm.value.agencyRate;
    this.agency.contactEmail = this.agencyForm.value.contactEmail;
    this.agency.contactPhone = this.agencyForm.value.contactPhone;
    this.agency.shortCode = this.agencyForm.value.shortCode;
    //this.agency.agencyId = Number(this.agencyForm.value.agencyId);
    this.agency.type = Number(this.agencyForm.value.type);
    this.agencyService.updateAgency(this.agency, this.modal);
  }
  // if agency type = 2, agency rate will show
  typeChange(): void {
    if (Number(this.agencyForm.value.type) === 2) {
      this.showAgencyRate = true;
      this.agencyForm.addControl('agencyRate',
        new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')]));
      if (this.update) {
        this.agencyForm.get('agencyRate').setValue(this.agency.agencyRate);
      }
    } else {
      this.showAgencyRate = false;
      this.agencyForm.removeControl('agencyRate');
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: DeactivateAgencyComponent,
      event: ev,
      translucent: true,
      componentProps: { agency: this.agency },
    });
    popover.onDidDismiss().then(() => {
      this.modal.dismiss();
    });
    return await popover.present();
  }
  // populate agency's current details for update modal
  populateAgency = async () => {
    this.agencyForm.patchValue(this.agency);
    this.agencyForm.controls['type'].setValue(this.agency.type.toString());
    this.agencyForm.controls['name'].setValue(this.agency.name, { onlySelf: true });
    if (this.agency.type === 2) {
      this.showAgencyRate = true;
      this.agencyForm.addControl('agencyRate',
        new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]));
      this.agencyForm.controls['agencyRate'].setValue(Number(this.agency.agencyRate.replace(/[^0-9.-]+/g, '')));
    }
  }
  ValidateInput(event){
    var regex = new RegExp("^[0-9]{0,9}(?:[.][0-9]{0,3})?$");
    var res = regex.test(event.target.value);
    if(res){
      return true;
    }
    event.preventDefault();
    event.target.value = event.target.value.replace("/^[0-9]{0,9}(?:[.][0-9]{0,3})?$/",'');
    return false;
  }

  ngOnInit() {
    console.log(this);
    this.agencyForm.valueChanges.subscribe(change => {
      if (this.agencyForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    this.agRate = this.agency.agencyRate.slice(1);
    // tslint:disable-next-line: max-line-length
    if (this.update) {
      this.populateAgency();
    }
  }
}


// need to add api url for environment.prod.ts when the time come//sasd
const baseApiPath = 'https://api.btbapp.lausd.net/';
//const baseApiPath = 'https://localhost:5000/api/';
const baseSsoPath = 'https://sso.btbapp.lausd.net/'
//const baseSsoPath = 'https://localhost:5000/'
// const baseApiPath = 'https://localhost:5000/';

const certificationApiUrl = baseApiPath + 'certifications/';
const enrichmentCodesApiUrl = baseApiPath + "enrichmentcodes/"
const schoolsApiUrl = baseApiPath + 'schools/';
const studentsApiUrl = baseApiPath + 'students/';
const activitiesApiUrl = baseApiPath + 'activities/';
const agenciesApiUrl = baseApiPath + 'agencies/';
const studentActivitiesApiUrl = 'assets/data/student-activities-dummy-data.json';
const studentHistoryApiUrl = 'assets/data/student-history-dummy-data.json';
const staffApiUrl = baseApiPath + 'staff/';
const schoolStudentApiUrl = baseApiPath + 'students/';
const awardsApiUrl = baseApiPath + 'awards/';
const authorizedPersonApiUrl = baseApiPath + 'authorizedpersons/';
const fundingSourceApiUrl = baseApiPath + 'fundingsource/';
const schoolStaffApiUrl = baseApiPath + 'staff/school/';
const reportsApiUrl = baseApiPath + 'reports/';
const attendanceApiUrl = baseSsoPath + 'attendancereports/';
const fiscalYearApiUrl = baseApiPath + 'fiscalyears/';
const calendarYearApiUrl = baseApiPath + 'calendaryears/';
const rolePermissionsApiUrl = baseApiPath + 'rolepermissions/';
const roleApiUrl = baseApiPath + 'roles/';
const operationalCalendarApiUrl = baseApiPath + 'operationalcalendar/';
const calendarApiUrl = baseApiPath + 'calendar/exceptions';
const ssoApiUrl = baseSsoPath + 'saml2/initiatesinglesignOn';
const ssoOutApiUrl = baseSsoPath + 'saml2/logout';
const ssoSetAgency = baseSsoPath + 'saml2/setagency';
const appVersion = "v2.1.5"
const agencyId = 1;
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  ssoLogin: ssoApiUrl,
  ssoLogout: ssoOutApiUrl,
  production: true,
  // API urls
  schoolsUrl: schoolsApiUrl,
  schoolStudentUrl: schoolStudentApiUrl,
  certificationUrl: certificationApiUrl,
  enrichmentCodesUrl: enrichmentCodesApiUrl,
  studentsUrl: studentsApiUrl,
  calendarYearUrl: calendarYearApiUrl,
  fiscalYearUrl: fiscalYearApiUrl,
  activitiesUrl: activitiesApiUrl,
  agenciesUrl: agenciesApiUrl,
  staffUrl: staffApiUrl,
  studentActivitiesUrl: studentActivitiesApiUrl,
  studentHistoryUrl: studentHistoryApiUrl,
  awardsUrl: awardsApiUrl,
  authorizedPersonApiUrl: authorizedPersonApiUrl,
  fundingSourceUrl: fundingSourceApiUrl,
  schoolStaffUrl: schoolStaffApiUrl,
  reportsUrl: reportsApiUrl,
  attendanceReportsUrl: attendanceApiUrl,
  rolePermissionsUrl: rolePermissionsApiUrl,
  roleApiUrl : roleApiUrl,
  operationalCalendarApiUrl: operationalCalendarApiUrl,
  calendarApiUrl: calendarApiUrl,
  // Azure AD variables
  tenant: '042a40a1-b128-4ac4-8648-016ffa121487',
  clientId: '042a40a1-b128-4ac4-8648-016ffa121487',
  postLogoutRedirectUrl: window.location.origin,
  redirectUrl: window.location.origin + '/auth-callback',
  responseType: 'id_token',
  agencyId: agencyId,
  sessionTimeout: 3600000,
  pageTitle: '',
  pageSubTitle: '',
  ssoSetAgency: ssoSetAgency,
  appVersion: appVersion
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
